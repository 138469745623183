import { useTranslation } from "react-i18next";
import { isBlank, isEmptyArray, isNotBlank } from "../../utils/uiUtils";
import { Link } from "react-router-dom";

export function CandidatesListView({ candidates, searchTerm = '' }) {
  const { t } = useTranslation();

  const candidatePageLink = (id) => {
    return isBlank(searchTerm)
      ? `/candidates/${id}`
      :`/candidates/${id}?q=${encodeURIComponent(searchTerm)}`
  }

  return (
    <>
      {!isEmptyArray(candidates) > 0 && (
        <table className="min-w-full divide-y divide-gray-300">
          <tbody className="divide-y divide-gray-200 bg-white">
            {candidates.map((item) => (
              <tr key={item.id}>
                <td className="whitespace-nowrap text-sm w-32 px-2 py-3 ml-6 text-left">
                  {item.lastName}, {item.firstName}
                  {isNotBlank(item.email) && <span className="text-gray-600"> ({item.email})</span>}
                </td>
                <td className="relative whitespace-nowrap pr-4 text-right text-sm font-medium sm:pr-0 w-28">
                  <Link
                    to={candidatePageLink(item.id)}
                    className="font-medium text-mps-ultramarine pr-4 dark:text-mps-ultramarine hover:underline"
                  >
                    {t("Candidates.ViewCandidate")}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}
