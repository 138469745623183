import React from "react";
import { useTranslation } from "react-i18next";
import { isNotBlank } from "../utils/uiUtils";
import { Link } from "react-router-dom";

export const ReturnToSearch = ({ searchTerm }) => {
  const { t } = useTranslation();
  return (
    <>
      {isNotBlank(searchTerm) && (
        <Link to={`/search?q=${encodeURIComponent(searchTerm)}`}
              className="text-sm font-sm text-mps-ultramarine dark:text-mps-ultramarine hover:underline">
          {t("Search.TextSearch.Back")}
        </Link>
      )}
    </>
  );
};
