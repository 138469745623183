import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BookingSelector } from "./BookingSelector";
import { getBookingLabel } from "../utils/uiUtils";
import { BookingCancellation } from "./BookingCancellation";
import {
  createInterviewBooking,
  removeInterviewBooking,
  getAvailableInterviewSlots,
  createGroupInterviewBooking,
  getAvailableGroupSlots,
  removeGroupInterviewBooking,
} from "../api";

export const Booking = ({
  applicant,
  title,
  modalTitle,
  label,
  formData,
  date,
  time,
  duration,
  type,
  slotType,
  handleBookingChange,
  disabled
}) => {
  const { t } = useTranslation();
  const [bookingSuggestions, setBookingSuggestions] = useState({});
  const [showBookingSelector, setShowBookingSelector] = useState(false);
  const [isLoadingBookings, setIsLoadingBookings] = useState(false);
  const [showBookingCancellation, setShowBookingCancellation] = useState(false);
  const [isRemovingBooking, setIsRemovingBooking] = useState(false);

  const formatISODate = (dateStr) => {
    const parts = dateStr.split("T");
    return parts[0];
  };

  const formatISOTime = (dateStr) => {
    return new Date(dateStr).toLocaleTimeString("en-GB", {
      timeZone: "Europe/Helsinki",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  const getAvailableIndividualSlots = async (applicant, skip = 0, append = false) => {
    setIsLoadingBookings(true);
    setShowBookingSelector(true);

    const response = await getAvailableInterviewSlots(formData.id, applicant.candidateId, skip);

    if (append) {
      const combinedSlots = [...bookingSuggestions.bookingSlots, ...response.bookingSlots];
      const combinedCount = bookingSuggestions.count + response.count;
      const combinedResponse = {
        "count": combinedCount,
        "totalCount": bookingSuggestions.totalCount,
        "skip": response.skip ?? 0,
        "bookingSlots": combinedSlots
      };
      setBookingSuggestions(combinedResponse);
    } else {
      setBookingSuggestions(response);
    }

    setIsLoadingBookings(false);
  };

  const getAvailableGroupSimulationSlots = async (applicant) => {
    setBookingSuggestions({})
    setIsLoadingBookings(true);
    setShowBookingSelector(true);

    const response = await getAvailableGroupSlots(formData.id, applicant.candidateId);
    setBookingSuggestions(response);
    setIsLoadingBookings(false);
  };

  const getAvailableSlots = (skip = 0, append = false) => {
    if (slotType === "IndividualInterview") {
      getAvailableIndividualSlots(applicant, skip, append)
        .catch((error) => console.log(error));
    } else {
      getAvailableGroupSimulationSlots(applicant)
        .catch((error) => console.log(error));
    }
  };

  const handleIndividualBookingSelection = async (slot, applicant) => {
    setIsLoadingBookings(true);

    const payload = {
      ...slot,
      candidateId: applicant.candidateId,
      ordinal: applicant.ordinal,
    };

    createInterviewBooking(formData.id, payload)
      .then((response) => {
        if (response.id) {
          const bookingData = {
            individualInterviewDate: formatISODate(slot.start),
            individualInterviewTime: formatISOTime(slot.start),
            individualInterviewBooking: response,
          };
          console.log("handleBookingSelection bookingData", bookingData);
          handleBookingChange(bookingData);
        }
      })
      .catch((error) => {
        console.error("Error creating booking:", error);
      })
      .finally(() => {
        setIsLoadingBookings(false);
        setShowBookingSelector(false);
      });
  };

  const handleGroupBookingSelection = async (slot, applicant) => {
    setIsLoadingBookings(true);

    const payload = {
      ...slot,
      candidateId: applicant.candidateId,
      ordinal: applicant.ordinal,
    };

    createGroupInterviewBooking(formData.id, payload)
      .then((response) => {
        if (response.id) {
          const bookingData = {
            groupSimulationDate: formatISODate(slot.start),
            groupSimulationTime: formatISOTime(slot.start),
          };
          console.log("handleGroupBookingSelection bookingData", bookingData);
          handleBookingChange(bookingData);
        }
      })
      .catch((error) => {
        console.error("Error creating group booking:", error);
      })
      .finally(() => {
        setIsLoadingBookings(false);
        setShowBookingSelector(false);
      });
  };

  const handleIndividualBookingRemoval = (applicant) => {
    setIsRemovingBooking(true);
    const payload = {
      start: new Date().toISOString(),
      end: new Date().toISOString(),
      candidateId: applicant.candidateId,
    };

    removeInterviewBooking(formData.id, payload)
      .then((response) => {
        if (response.removed) {
          const bookingData = {
            individualInterviewDate: undefined,
            individualInterviewTime: undefined,
            individualInterviewBooking: undefined,
          };
          handleBookingChange(bookingData);
        }
      })
      .catch((error) => {
        console.error("Error removing booking:", error);
      })
      .finally(() => {
        setIsRemovingBooking(false);
        setShowBookingCancellation(false);
      });
  };

  const handleGroupBookingRemoval = (applicant) => {
    setIsRemovingBooking(true);
    const payload = {
      start: new Date().toISOString(),
      end: new Date().toISOString(),
      candidateId: applicant.candidateId,
      ordinal: applicant.ordinal,
    };

    removeGroupInterviewBooking(formData.id, payload)
      .then((response) => {
        if (response.removed) {
          const bookingData = {
            groupSimulationDate: undefined,
            groupSimulationTime: undefined,
            groupInterviewBooking: undefined,
          };
          handleBookingChange(bookingData);
        }
      })
      .catch((error) => {
        console.error("Error removing booking:", error);
      })
      .finally(() => {
        setIsRemovingBooking(false);
        setShowBookingCancellation(false);
      });
  };

  const handleBookingSelection = (slot, applicant) => {
    if (slotType === "IndividualInterview") {
      handleIndividualBookingSelection(slot, applicant);
    } else {
      handleGroupBookingSelection(slot, applicant);
    }
  };

  const handleBookingRemoval = (applicant) => {
    if (slotType === "IndividualInterview") {
      handleIndividualBookingRemoval(applicant);
    } else {
      handleGroupBookingRemoval(applicant);
    }
  };

  const fetchMore = (event, skip) => {
    event.preventDefault();
    getAvailableSlots(skip, true);
  }

  const isBookingSelectable = (type === "Teams" || type === "Office");
  const buttonClassName = (isBookingSelectable && !disabled) ? "text-blue-500 hover:text-blue-800" : "text-gray-500";
  const cancelButtonClassName = !disabled ? "text-red-500 hover:text-red-800" : "text-gray-500";
  const buttonText = isBookingSelectable ? t("BookingSelector.ScheduleMeeting") : t("BookingSelector.NotAvailable");

  return (
    <div className="flex mt-2 gap-x-2 px-4">
      <div className="w-full">
        <p className="font-semibold text-sm">{title}:</p>
        <p className="text-xs font-light">
          {applicant.candidateId ? (
            date ? (
              <>
                <span>{label}</span>
                <button
                  type="button"
                  className={"text-xs px-2 py-1 rounded-md " + cancelButtonClassName}
                  onClick={() => setShowBookingCancellation(true)}
                  disabled={disabled}
                >
                  {t("BookingSelector.RemoveBooking")}
                </button>
              </>
            ) : (
              <>
                <span className="font-medium">
                  {t("BookingSelector.Unscheduled")}
                </span>
                <button
                  type="button"
                  className={"text-xs px-2 py-1 rounded-md " + buttonClassName}
                  onClick={() => getAvailableSlots(0, false)}
                  disabled={!isBookingSelectable || disabled}
                >
                  {buttonText}
                </button>
              </>
            )
          ) : (
            <span className="font-medium">
              {t("BookingSelector.SaveBeforeBooking")}
            </span>
          )}
        </p>
        <BookingSelector
          title={modalTitle}
          bookingSuggestions={bookingSuggestions}
          open={showBookingSelector}
          setOpen={setShowBookingSelector}
          action={(slot) => handleBookingSelection(slot, applicant)}
          duration={duration}
          type={type}
          isFetching={isLoadingBookings}
          interviewType={slotType}
          fetchMoreAction={fetchMore}
        />
        <BookingCancellation
          open={showBookingCancellation}
          setOpen={setShowBookingCancellation}
          applicant={applicant}
          time={getBookingLabel(
            date,
            time,
            duration
          )}
          action={(applicant) => handleBookingRemoval(applicant)}
          isRemoving={isRemovingBooking}
        />
      </div>
    </div>
  );
};
